
body{
    // background: url('../../images/bg-login.png') no-repeat center center;
    // background-size: cover; /* Faz o fundo cobrir toda a tela */
    height: 100vh; /* Garante que o corpo ocupe a altura da janela */
    margin: 0; /* Remove qualquer margem padrão */
    padding: 0; /* Remove qualquer padding padrão */
    
    
    .login-box{
        max-width: 400px ;
        margin: 100px auto;
        padding: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        margin-top: 12vh;
        padding-bottom: 40px;
        border-radius: 6px;
        
        .form{
            max-width: 320px;
            margin:  auto;
            
            .ant-input-prefix{
                color: #bababa
            }

        }
    }

    .login-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: linear-gradient(to bottom, #FF762C 50%, #f5f5f5 50%);
      }
      
      .login-box {
        background-color: white;
        padding: 40px;
        width: 400px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        .btn-submit{
            margin-top: 20px;
        }


      }

      

}



