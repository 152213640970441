/* src/components/style.scss */


.ant-menu-item-selected {
    background-color: #fc8135 !important;
  }
  

  .ant-menu-item-selected a {
    color: white !important; /* Ou qualquer outra cor desejada */
  }
  

  .ant-menu-item:hover {
    background-color: #fc8135 !important;
  }
  
  .ant-menu-item-selected .anticon {
    color: white !important; /* Cor do ícone ativo */
  }


  
.ant-menu {
    .ant-menu-item{
        width: 100%;
        border-radius: 0;
        margin: 0 0 2px 0;
        padding: 0;
    }

    li:first-child {
      margin-top: 24px;
    }
}

.ant-menu-inline-collapsed{

 .ant-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    
    i {
      margin-right: 0 !important; /* Remove o espaço padrão ao lado do ícone */
    }

    .ant-menu-title-content{
        display: none !important;
    }
  }
}  
