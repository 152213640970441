

.ant-layout-content{

  min-height: 80vh;

  h2{
    margin-top: 0px;
  }
 
}
.btn-logout{
  color: #393E46 ;// #17375e
  font-weight: bold;
}