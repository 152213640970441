/* src/styles/main.scss */
$primary-color: #1890ff;
$sidebar-width: 200px;

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
  overflow: auto;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    background-color: $primary-color;
    color: white;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
  }

  .sidebar {
    width: $sidebar-width;
    background-color: #001529;
    color: white;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    .menu-item {
      padding: 10px;
      &:hover {
        background-color: #1890ff;
      }
    }
  }

  .content {
    margin-left: $sidebar-width;
    padding: 24px;
    background-color: white;
    min-height: 280px;

  }
}

h2{
  font-size: 22px !important;
  margin-bottom: 20px !important;
  color: #212121 !important;
}
