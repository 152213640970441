

.subpagessider{
    background-color: #bbbbbb !important;
}

.ant-card{
    margin-top: 16px !important;
}



.ant-layout-sider-children{
    .ant-tabs{
        .ant-tabs-nav{
            .ant-tabs-nav-wrap{
                .ant-tabs-nav-list{

                    .ant-tabs-tab{
                        padding-left: 10px !important;
                        margin-bottom: 2px !important;
                        margin-top: 0px !important;
                    }

                    .ant-tabs-tab-active{
                        background: rgb(2 71 133 / 14%) !important;

                        .ant-tabs-tab-btn{

                            color: #323232 !important;
                            
                            // font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}